<template>
	<div id="app" class="d-flex flex-column">
		<navbar/>
		<div v-bind:style="section_vh" class="wrapper-body bg-home">
			<router-view/>
		</div>
		<footer-block/>
	</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import FooterBlock from "./components/Footer.vue";

export default {
	data() {
		return {
			section_vh: "min-height: 100vh;",
			games: "",
			ins_data: null
		};
	},
	methods: {

	},
	components: {Navbar, FooterBlock},
	mounted() {
		let navbar_height = document.getElementById("navbar-el").offsetHeight;
		let footer_height = document.getElementById("footer-el").clientHeight;
		let fn = navbar_height + footer_height;
		this.section_vh = "min-height:calc(100vh - " + fn + "px;";
		console.log(navbar_height, footer_height);

		if (localStorage.getItem("lang") !== null) {
			this.$i18n.locale = window.localStorage.getItem("lang");
		}
	},
	created() {

	}
};
</script>

<style>
.wrapper-body {
	height: 100%;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
}

html {
	overflow: auto;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1400px;
	}
}
</style>

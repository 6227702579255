<template>
  <div id="navbar-el">
    <div class="p-lg-0 p-md-0 header-top">
      <b-container>
        <div class="d-flex justify-content-between">
          <div class="text-uppercase d-none d-md-block d-lg-block d-xl-block">
            <b-link class="nav-link" to="/">{{ $t("t_l_o_p_t") }}</b-link>
          </div>
          <div
            class="text-lg-center d-none d-md-block d-lg-block d-xl-block flex-fill"
            style="max-width: 560px;"
          >
            <ul class="nav justify-content-around">
              <li class="nav-item">
                <b-nav-item to="/efootball">{{
                  $t("sports.efootball")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/ehockey">{{
                  $t("sports.ehockey")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/ebasketball">{{
                  $t("sports.ebasketball")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/rocket-league">{{
                  $t("sports.rocket_league")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/counter-strike">{{
                  $t("sports.counter_strike")
                }}</b-nav-item>
              </li>
            </ul>
          </div>
          <div
            class="text-lg-center d-md-none flex-fill"
            style="max-width: 280px;"
          >
            <ul class="nav justify-content-between">
              <li class="nav-item">
                <b-nav-item to="/efootball">{{ $t("sports.fifa") }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/ehockey">{{ $t("sports.nhl") }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/ebasketball">{{
                  $t("sports.nba")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/rocket-league">{{
                  $t("sports.rl")
                }}</b-nav-item>
              </li>
              <li class="nav-item">
                <b-nav-item to="/counter-strike">{{
                  $t("sports.cs")
                }}</b-nav-item>
              </li>
            </ul>
          </div>
          <div class="text-right ml-5 ml-md-4">
            <b-nav>
              <b-nav-item class="lang" @click="changeLang('ru')">RU</b-nav-item>
              <li class="separator">|</li>
              <b-nav-item class="lang" @click="changeLang('en')">EN</b-nav-item>
            </b-nav>
          </div>
        </div>
      </b-container>
    </div>

    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="p-lg-0 p-md-0"
      :class="headerColorBg"
    >
      <b-container>
        <b-navbar-brand :to="routerPath" class="text-left py-2 px-0">
          <img :src="logo(routerName)" alt="Logo" class="logo_images" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto text-uppercase text-white">
            <b-nav-item :to="routerPath + '/tours'">{{
              $t("nav_menu.tours")
            }}</b-nav-item>
            <b-nav-item :to="routerPath + '/rules'">{{
              $t("nav_menu.rules")
            }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      routerPath: this.routerPath,
      routerName: this.routerName,
      headerColorBg: this.headerColorBg,
      folderImages: this.folderImages
    };
  },
  watch: {
    $route() {
      this.routerPath = this.$route.matched[0].path;
      this.routerName = this.$route.matched[0].path.slice(1);
      this.headerColorBg = "head-bg-" + this.routerName;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.en === null) this.changeLang("en");
    }, 100);
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      window.localStorage.setItem("lang", lang);
    },
    logo(path) {
      if (path === "efootball")
        return require("../assets/img/fifa/logo_head.svg");
      else if (path === "ehockey")
        return require("../assets/img/nhl/logo_head.svg");
      else if (path === "ebasketball")
        return require("../assets/img/nba/logo_head.svg");
      else if (path === "rocket-league")
        return require("../assets/img/rl/logo_head.svg");
      else if (path === "counter-strike")
        return require("../assets/img/cs/logo_head.svg");
    }
  }
};
</script>

<style scoped>
/*
.navbar-collapse.collapse.show {
  margin: 15px 0;
}
.navbar-collapse.collapse.show .nav-item {
  padding: 5px 0;
}
*/

.header-top {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  height: 40px;
  line-height: 40px;
  background: #231f20;
  font-size: 12px;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-dark .navbar-nav .nav-link {
  color: #000000;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #000000;
}

.nav-link {
  display: block;
  padding: 0 0.3rem;
}

a.nav-link {
  color: #fff;
}

a.nav-link:hover {
  text-decoration: underline;
}

.separator {
  font-size: 10px;
}

.bg-dark {
  background-color: transparent;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.67);
}

.head-bg-ehockey {
  background: linear-gradient(90.06deg, #59c3ff 0%, #42b0ff 99.96%);
}
.head-bg-ebasketball {
  background: linear-gradient(90.06deg, #03fbfb 0%, #00f0f0 99.96%);
}
.head-bg-efootball {
  background: linear-gradient(90.06deg, #8fff37 0%, #61dc00 99.96%);
}
.head-bg-rocket-league {
  background: linear-gradient(90deg, #ff3fe0 0%, #ff1edb 100%);
}
.head-bg-counter-strike {
  background: linear-gradient(90.06deg, #ffb739 0%, #ffa200 99.96%);
}
.logo_images {
  height: 60px;
}

@media only screen and (max-width: 375px) {
  .logo_images {
    height: 40px;
  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .logo_images {
    height: 50px;
  }
}
</style>
<style>
.bg-home {
  background-image: url("../assets/img/background.jpg");
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
}
.navbar-dark .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../assets/img/toggler-icon.svg") !important;
  transition: 0.5s;
}
.navbar-dark .navbar-toggler.not-collapsed .navbar-toggler-icon {
  background-image: url("../assets/img/toggler-icon-active.svg") !important;
}
.blurred-bg {
  background-color: rgba(47, 47, 47, 0.6) !important;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.card {
  background-color: transparent;
  border: none;
}

.card-body {
  padding: 0.15rem 0.75rem;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #fff;
  text-transform: uppercase !important;
  font-size: 12px;
  margin: 0 2px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.48);
  border-color: transparent;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.24);
  color: #fff;
}

.card-text {
  font-size: 14px;
}

.card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.card-header-tabs {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-bottom: 0;
}

.game-border-bottom:last-child {
  border-bottom: transparent;
}

.game-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.nba-text-color {
  color: #03fbe9 !important;
}
.fifa-text-color {
  color: #70fb03 !important;
}
.nhl-text-color {
  color: #46ceff !important;
}
.cs-text-color {
  color: #ffb739 !important;
}
.rl-text-color {
  color: #ff3fe0 !important;
}
.section-vh {
  min-height: 100vh;
}
</style>

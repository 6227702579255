import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import Axios from "axios";
import Meta from "vue-meta";
import i18n from "./i18n";

Vue.use(Meta);
Vue.prototype.$http = Axios;
Vue.prototype.$url = process.env.VUE_APP_BASE_URL;
Vue.prototype.$twitch_domain = process.env.VUE_APP_TWITCH_DOMAIN_URL;

Vue.config.productionTip = false;
//console.log(process.env);
//console.log(process.env.VUE_APP_I18N_LOCALE);

Vue.mixin({
  methods: {
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
